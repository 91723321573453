import React from "react";
import PropTypes from "prop-types"

const MadaSectionVideoMedia = ({ video }) => {
  return (
    <React.Fragment>
      <div className="imagery" data-aos="fade" data-aos-once="true">
        <div className="video-wrapper">
          <video autoPlay loop muted className="foreground video wow fadeInUp" data-wow-delay="400" data-wow-duration="1400">
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </div>
    </React.Fragment>
  )
}

MadaSectionVideoMedia.propTypes = {
  video: PropTypes.string
}

export default MadaSectionVideoMedia
