import React from "react";
import PropTypes from "prop-types"

const MadaSectionThreeMedia = ({ left, right, principal }) => {
  return (
    <React.Fragment>
      <div className="imagery">
        <div className="background wow fadeInLeft" style={{ backgroundImage: `url(${left})` }}
          data-wow-delay="300"
          data-wow-duration="1400"></div>
        <div className="background wow fadeInRight" style={{ backgroundImage: `url(${right})` }}
          data-wow-delay="200"
          data-wow-duration="1400"></div>
        <img
          className="foreground wow fadeInUp"
          src={principal}
          data-wow-delay="400"
          data-wow-duration="1400"
          alt="Mada"
        />
      </div>
    </React.Fragment>
  )
}

MadaSectionThreeMedia.propTypes = {
  left: PropTypes.string,
  right: PropTypes.string,
  principal: PropTypes.string
}

export default MadaSectionThreeMedia
