import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Link } from "gatsby";
import addToMailchimp from 'gatsby-plugin-mailchimp'

import 'src/components/Common/MadaPress/_styles.scss';

function MadaPress({title, logos}) {

  return (
    <section className={`mada-press`}>
      <h2><Link to="/press">{title}</Link></h2>
      <div className="press-logos">
        {
          logos.map((logo, key) => {
            return (
              <Link key={key} to="/press">
                <img src={logo.file.url} alt="" className="press-logo--images"/>
              </Link>
            )
          })
        }
      </div>
    </section>
  )
}

export default MadaPress
