import React from "react";
import { graphql } from "gatsby";
import Layout from "src/components/Layout/Layout";
import SEO from "src/components/Seo/Seo";
import MadaSection from "src/components/Common/MadaSection/MadaSection";
import MadaSectionContent from "src/components/Common/MadaSection/MadaSectionContent";
import MadaSectionBackgroundMedia from "src/components/Common/MadaSection/MadaSectionBackgroundMedia";
import MadaSectionThreeMedia from "src/components/Common/MadaSection/MadaSectionThreeMedia";
import MadaSectionVideoMedia from "src/components/Common/MadaSection/MadaSectionVideoMedia";
import MadaNewsletter from "src/components/Common/MadaNewsletter/MadaNewsletter";
import MadaPress from "src/components/Common/MadaPress/MadaPress";
import MadaButton from "src/components/Common/MadaButton/MadaButton"
import MadaContent from "src/components/Common/MadaContent/MadaContent"
import MadaValues from "src/components/MadaValues/MadaValues"
import MadaTeam from "src/components/MadaTeam/MadaTeam"
import MadaPressNote from "src/components/MadaPressNote/MadaPressNote"
import ImageSeparator from "src/components/Common/ImageSeparator/ImageSeparator"
import TopBanner from "src/components/Common/TopBanner/TopBanner"
import Form from "src/components/Contact/Form/Form"
import Emails from "src/components/Contact/Emails/Emails"

const Page = ({ data }) => {
  const { slug, sections, metaTitle, metaDescription, title } = data.contentfulPage
  return (
    <Layout {...( (slug === 'contact' || title.includes("PageInfo"))  ? {blackMenu: true} : {})}>
      <SEO title={metaTitle} description={metaDescription} />
      <>
        <div className={`${title.includes("PageInfo") ? 'page-information' : ''} ${slug === 'contact' ? 'page-wrapper' : ''}`}>
        {
          sections.map((section, key) => {
            if ( section.__typename === "ContentfulTopBanner") {
              return (
                <TopBanner
                  key={key}
                  androidLink={section.androidLink}
                  appleLink={section.appleLink}
                  title={section.title}
                  button={section.button ? (<MadaButton text={section.button.value} link={section.button.link} linkTarget={section.button.linkTarget} />) : undefined}
                  backgroundImage={section.image.file.url}
                />
              )
            }
            if ( section.__typename === "ContentfulContent") {
              return (
                <MadaContent key={key}>
                  {section.body.json}
                </MadaContent>
              )
            }
            if ( section.__typename === "ContentfulNewsletter") {
              return(
                <MadaNewsletter
                  key={key}
                  title={section.title}
                  placeholder={section.placeholder}
                  formname={section.formname}
                  successTitle={section.successTitle}
                  image={section.image ? section.image.file.url: undefined} />)
            }
            if ( section.__typename === "ContentfulPressNote") {
              return(
                <MadaPressNote
                  key={key}
                  title={section.title}
                  link={section.link}
                  images={section.images}
                />)
            }
            if ( section.__typename === "ContentfulPressHome") {
              return(
                <MadaPress
                  key={key}
                  title={section.title}
                  logos={section.logos}
                />)
            }
            if ( section.__typename === "ContentfulImageSeparator") {
              return(<ImageSeparator key={key} backgroundImage={section.image.file.url} />)
            }
            if ( section.__typename === "ContentfulSectionBackground") {
              let styleClass = section.imageOrientation === "Left" ? 'imgLeft' : 'imgRight';
              let colorClass = section.color === "white" ? 'white' : 'dark';
              let leftContent = null;
              let rightContent = null;
              if (styleClass === 'imgLeft') {
                leftContent = (<MadaSectionBackgroundMedia imagePrincipalUrl={section.imagePrincipal.file.url}
                  backgroundImageUrl={section.imageBackground.file.url} device="desktop" />);
                  rightContent = (<>
                    <MadaSectionContent title={section.title} text={section.text} step={section.step} comingSoon={section.comingSoon} />
                    <MadaSectionBackgroundMedia imagePrincipalUrl={section.imagePrincipal.file.url}
                    backgroundImageUrl={section.imageBackground.file.url} device="mobile" />
                  </>);
              }else{
                leftContent = (<>
                  <MadaSectionContent title={section.title} text={section.text} step={section.step} comingSoon={section.comingSoon} />
                  <MadaSectionBackgroundMedia imagePrincipalUrl={section.imagePrincipal.file.url}
                  backgroundImageUrl={section.imageBackground.file.url} device="mobile" /></>);
                rightContent = (<MadaSectionBackgroundMedia
                  imagePrincipalUrl={section.imagePrincipal.file.url}
                  backgroundImageUrl={section.imageBackground.file.url}
                  device="desktop" />);
              }
              return (
                <MadaSection
                  key={key}
                  styleClass={styleClass}
                  color={colorClass}
                  left={leftContent}
                  right={rightContent}
                />
              )
            }
            if ( section.__typename === "ContentfulSectionThreeImages") {
              let styleClass = section.imageOrientation === "Left" ? 'imgLeft' : 'imgRight';
              let colorClass = section.color === "white" ? 'white' : 'dark';
              let leftContent = null;
              let rightContent = null;
              if (styleClass === 'imgLeft') {
                leftContent = (
                  <MadaSectionThreeMedia
                    left={section.imageBackgroundLeft.file.url}
                    right={section.imageBackgroundRight.file.url}
                    principal={section.imagePrincipal.file.url} />
                  );
                  rightContent = (<MadaSectionContent title={section.title} text={section.text} step={section.step} comingSoon={section.comingSoon} />);
              }else{
                leftContent = (<MadaSectionContent title={section.title} text={section.text} step={section.step} comingSoon={section.comingSoon} />);
                rightContent = (
                  <MadaSectionThreeMedia
                    left={section.imageBackgroundLeft.file.url}
                    right={section.imageBackgroundRight.file.url}
                    principal={section.imagePrincipal.file.url} />
                  );
              }
              return (
                <MadaSection
                  key={key}
                  styleClass={styleClass}
                  color={colorClass}
                  left={leftContent}
                  right={rightContent}
                />
              )
            }
            if ( section.__typename === "ContentfulSectionVideo") {
              let styleClass = section.imageOrientation === "Left" ? 'imgLeft' : 'imgRight';
              let colorClass = section.color === "white" ? 'white' : 'dark';
              let leftContent = null;
              let rightContent = null;
              if (styleClass === 'imgLeft') {
                leftContent = (<MadaSectionVideoMedia video={section.video.file.url} />);
                rightContent = (<MadaSectionContent title={section.title} text={section.text} step={section.step} comingSoon={section.comingSoon} />);
              }else{
                leftContent = (<MadaSectionContent title={section.title} text={section.text} step={section.step} comingSoon={section.comingSoon} />);
                rightContent = (<MadaSectionVideoMedia video={section.video.file.url} />);
              }
              return (
                <MadaSection
                  key={key}
                  styleClass={styleClass}
                  color={colorClass}
                  left={leftContent}
                  right={rightContent}
                />
              )
            }
            if ( section.__typename === "ContentfulTeam") {
              return (
                <MadaTeam key={key} title={section.title} members={section.members} />
              )
            }
            if ( section.__typename === "ContentfulValues") {
              return (
                <MadaValues key={key} title={section.title} values={section.values} />
              )
            }
            if ( section.__typename === "ContentfulForm") {
              return (
                <Form
                  key={key}
                  title={section.title}
                  inputs={section.inputs}
                  buttonText={section.buttonText}
                  leftTitle={section.leftTitle}
                  leftText={section.leftText}
                  successText={section.successText}
                  successTitle={section.successTitle} />
              )
            }
            if ( section.__typename === "ContentfulFormEmails") {
              return (<Emails key={key} data={section.emails} />)
            }
          })
        }
        </div>
      </>
    </Layout>
  );
};
export default Page;

export const pageQuery = graphql`
  query($id: String!) {
    contentfulPage(id: {eq: $id}){
      id
      slug
      title
      metaTitle
      metaDescription
      sections {
        ... on Node {
          ... on ContentfulImageSeparator {
            id
            internal {
              type
            }
            image {
              file {
                url
              }
            }
          }
        }
        ... on Node {
          ... on ContentfulNewsletter {
            id
            internal {
              type
            }
            title
            placeholder
            successTitle
            formname
            image {
              file {
                url
              }
            }
          }
        }
        ... on Node {
          ... on ContentfulPressHome {
            id
            internal {
              type
            }
            title
            logos {
              file {
                url
              }
            }
          }
        }
        ... on Node {
          ... on ContentfulPressNote {
            images {
              file {
                url
              }
            }
            title
            id
            link
          }
        }
        ... on Node {
          ... on ContentfulSectionBackground {
            id
            color
            comingSoon
            imageOrientation
            text
            title
            step
            imageBackground {
              file {
                url
              }
            }
            imagePrincipal {
              file {
                url
              }
            }
          }
        }
        ... on Node {
          ... on ContentfulTopBanner {
            button {
              value
              link
              linkTarget
            }
            androidLink
            appleLink
            title
            image {
              file {
                url
              }
            }
          }
        }
        ... on Node {
          ... on ContentfulSectionVideo {
            id
            text
            title
            video {
              file {
                url
              }
            }
            color
            comingSoon
            step
          }
        }
        ... on Node {
          ... on ContentfulContent {
            id
            body {
              json
            }
          }
        }
        ... on Node {
          ... on ContentfulTeam {
            title
            members {
              name
              description
              image {
                file {
                  url
                }
              }
            }
          }
        }
        ... on Node {
          ... on ContentfulValues {
            title
            values {
              title
              text
            }
          }
        }
        ... on Node {
          ... on ContentfulForm {
            id
            buttonText
            title
            leftTitle
            leftText {
              leftText
            }
            successTitle
            successText {
              successText
            }
            inputs {
              width
              placeholder
              inputType
              required
              name
            }
          }
        }
        ... on Node {
          ... on ContentfulFormEmails {
            id
            emails {
              title
              email
            }
          }
        }
        ... on Node {
          ... on ContentfulSectionThreeImages {
            id
            imageBackgroundLeft {
              file {
                url
              }
            }
            imageBackgroundRight {
              file {
                url
              }
            }
            imagePrincipal {
              file {
                url
              }
              title
            }
            imageOrientation
            comingSoon
            color
            text
            title
            step
          }
        }
      }
    }
  }
`;
