import React from "react";
import PropTypes from "prop-types"

import 'src/components/Common/MadaSection/_styles.scss';

const MadaSection = ({ color, left, right, styleClass  }) => {
  return (
    <section >
      <div className={`mada-section ${styleClass} ${color}`}>
        {left}
        {right}
      </div>
    </section>
  )
}

MadaSection.propTypes = {
  color: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node
}

export default MadaSection
