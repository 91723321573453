import PropTypes from "prop-types"
import React from "react"

import MadaMember from "src/components/MadaTeam/MadaMember/MadaMember";

import 'src/components/MadaTeam/_styles.scss';

const MadaTeam = ({ title, members }) => (
  <section className={`mada-team`}>
    <h2 className="title">{title}</h2>
    <div className="team-wrapper">
    {
      members.map((member, key) => {
        return (
          <MadaMember
            key={key}
            image={member.image.file.url}
            name={member.name}
            description={member.description}
          />
        )
      })
    }
    </div>
  </section>
)

MadaTeam.propTypes = {
  title: PropTypes.string
}

export default MadaTeam
