import PropTypes from "prop-types"
import React from "react"

import 'src/components/MadaValues/_styles.scss';

const Value = ({ children, title }) => (
  <div className="value">
    <h3 className="value-title">{title}</h3>
    <p className="value-content">
      {children}
    </p>
  </div>
)

const MadaValues = ({ title, values }) => (
  <section className={`mada-values`}>
    <h2 className="title">{title}</h2>
      <div className="value-wrapper">
      {
        values.map((value, key) => {
          return (
            <Value key={key} title={value.title}>
              {value.text}
            </Value>
          )
        })
      }
    </div>
  </section>
)

MadaValues.propTypes = {
  title: PropTypes.string
}

export default MadaValues
