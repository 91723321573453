import React from "react";
import PropTypes from "prop-types"

import 'src/components/Common/ImageSeparator/_styles.scss';

const ImageSeparator = ({ backgroundImage }) => {
  return (
    <section className='hero-separator' style={{ backgroundImage: `url(${backgroundImage})` }} ></section>
  )
}

ImageSeparator.propTypes = {
  backgroundImage: PropTypes.string,
}

export default ImageSeparator
