import PropTypes from "prop-types"
import React from "react"

import 'src/components/MadaTeam/MadaMember/_styles.scss';

const MadaMember = ({ image, name, description }) => (
  <div className="team-member">
  	<div className="team-member-image" style={{ backgroundImage: `url(${image})` }}></div>
  	<p className="team-member-name">{name}</p>
  	<p className="team-member-description">{description}</p>
  </div>
)

MadaMember.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
}

export default MadaMember
