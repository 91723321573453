import React from "react";
import PropTypes from "prop-types"

const MadaSectionContent = ({ step, title, text, comingSoon }) => {
  return (
    <React.Fragment>
      <div className="content">
        <div className={`content-wrapper ${comingSoon ? 'coming-soon-padding' : ''}`}>
          {
            step > 0 &&
              <h3 className="step">Step {step}</h3>
          }
          {
            comingSoon &&
              <h3 className="content-tag">Coming Soon</h3>
          }
          <h2 className="title">{title}</h2>
          <p className="text">{text}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

MadaSectionContent.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  step: PropTypes.string,
  comingSoon: PropTypes.bool
}

export default MadaSectionContent
