import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types"
import addToMailchimp from 'gatsby-plugin-mailchimp'

import 'src/components/Common/MadaNewsletter/_styles.scss';

function MadaNewsletter({ image, title, placeholder, successTitle, formname }) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setMessage(successTitle)
  }, []);

  const handleEmailChange = (event)=> {
    setEmail(event.target.value);
  }

  const handleKeyPress = (event) => {
  if (event.key === 'Enter'){
    submitForm();
  }
}

  const submitForm = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(email.toLowerCase())) {
      setSuccess(true);
      addToMailchimp(email, {
        FORMNAME: formname.toUpperCase()
      })
      .then(data => {
        console.log(data)
        if(data.result === 'error'){
          setMessage("Oops an error occurred!");
        }
      })
      .catch((error) => {
        console.log(error);
      })
    }else{
      console.log('error');
    }
  }

  return (
    <section
      className={`mada-newsletter ${image ? 'image': 'no-image'}`}
      style={{ backgroundImage: `url(${image})` }}>
      <div className="signup-form-container">
        <h2>{title}</h2>
        <div className="newsletter-form">
          <input type="email" name="email" className="email-input" onChange={handleEmailChange} onKeyPress={handleKeyPress} placeholder={placeholder} />
          <button className="newsletter-submit" onClick={submitForm}>
            Submit
          </button>
        </div>
      </div>
      <div className={`message ${success ? 'success' : ''}`}>{message}</div>
    </section>
  )
}

MadaNewsletter.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
}

export default MadaNewsletter
