import React from "react";
import PropTypes from "prop-types"

const MadaSectionBackgroundMedia = ({ backgroundImageUrl, imagePrincipalUrl, device }) => {
  return (
    <React.Fragment>
      <div className={`imagery ${device === 'desktop' ? 'wow fadeIn desktop': 'mobile'}`} style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
        <img
          className={`foreground wow ${device === 'desktop' ? 'fadeInRight': 'fadeIn'}`}
          data-wow-delay="300"
          data-wow-duration="1400"
          data-wow-offset="240"
          src={imagePrincipalUrl}
          alt="Mada"
        />
      </div>
    </React.Fragment>
  )
}

MadaSectionBackgroundMedia.propTypes = {
  backgroundImageUrl: PropTypes.string,
  imagePrincipalUrl: PropTypes.string
}

export default MadaSectionBackgroundMedia
