import React from "react"

import 'src/components/Contact/Emails/_styles.scss';

const Emails = ({ data }) => (
  <div className="emails-container">
      {
        data.map((email, key) => {
          return (
            <div key={key} className="columns link-content contact-link-content">
              <h2 className="title">{email.title}</h2>
              <div className="content-wrapper">
                <div className="content">
                  <a href="mailto:partnerships@themadaapp.com">→ {email.email}</a>
                </div>
              </div>
            </div>
          )
        })
      }
  </div>
)

export default Emails
