import React from "react"

import 'src/components/Contact/Form/_styles.scss';

const Form = ({ inputs, title, buttonText, leftTitle, leftText, successTitle, successText }) => (
  <div className="columns contact-content">
		<div>
			<h1 className="title">{leftTitle}</h1>
			<p className="contact-text">
        {leftText.leftText}
      </p>
		</div>
		<div>
			<div className="content">
				<div className="contact-form-container">
					<h2 className="title">{title}</h2>
					<form method="post" action="#" className="contact-form" id="contact-form" data-netlify-honeypot="bot-field" data-netlify="true">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact-form" />
            {
              inputs.map((input, key) => {
                if (input.inputType !== 'textarea') {
                  return(
                    <input key={key} className={input.width} type={input.inputType} name={input.name} placeholder={input.placeholder} {...(input.required ? {required: true} : {})} />
                  )
                }
                if (input.inputType === 'textarea') {
                  return(
                    <textarea key={key} name={input.name} placeholder={input.placeholder} required />
                  )
                }
              })
            }
					  <button type="submit">{buttonText}</button>
					</form>
				</div>
				<div className="contact-form-message">
					<h2 className="title">{successTitle}</h2>
					<p className="message-content">{successText.successText}</p>
				</div>
			</div>
		</div>
	</div>
)

export default Form
