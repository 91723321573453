import React from "react";
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import appleStore from 'src/assets/apple-store.png';
import playStore from 'src/assets/play-store.png';
import chevronScrollDown from 'src/assets/chevron-white-down-thin.png';

import 'src/components/Common/TopBanner/_styles.scss';

const TopBanner = ({ title, backgroundImage, hero, button, androidLink, appleLink }) => {
  const data = useStaticQuery(graphql`
    query TitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <section className='hero-banner overlay-grey' style={{ backgroundImage: `url(${backgroundImage})` }} >
      <div className="hero-content">
        <h1>{title}</h1>
        {button}
      </div>
      <div className="hero-store-info desktop">
        <span>For <a href={appleLink} target='_blank' rel="noopener noreferrer"><img src={appleStore} alt={`${data.site.siteMetadata.title} - Apple`} />
        <strong>iphone</strong></a>
        {/*and <a href={androidLink} target='_blank' rel="noopener noreferrer"><img src={playStore} alt={`${data.site.siteMetadata.title} - Android`} /><strong>Android!</strong></a>*/}
        </span>
    	</div>
      <div className="scroll-down-container">
    		<img src={chevronScrollDown} alt="Scroll Down" />
    	</div>
    </section>
  )
}

TopBanner.propTypes = {
  title: PropTypes.string,
  backgroundImage: PropTypes.string,
  button: PropTypes.node,
}

export default TopBanner
