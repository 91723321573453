import React, { useState } from 'react';

import 'src/components/MadaPressNote/_styles.scss';

function MadaPressNote({ link, images, title }) {
  const [isModalOpen, setisModalOpen] = useState(false);

  const handleModalOpen = (value) => {
    setisModalOpen(value);
  }

  return (
    <article className={`mada-press-note`}>
      {
        isModalOpen &&
        <>
          <div className={`overlay ${isModalOpen ? 'show' : ''}`} onClick={()=>handleModalOpen(!isModalOpen)}></div>
          <span className={`modal ${isModalOpen ? 'show' : ''}`} onClick={()=>handleModalOpen(!isModalOpen)}>
            <span className="modal--wrapper">
              <img src={images[0].file.url} />
              <span className="close--modal">X</span>
            </span>
          </span>
        </>
      }
      <div className="mada-press-note--wrapper">
        {
          link &&
          <a href={link} target="_blank" rel="no-follow">
            <div className="mada-press-note--wrapper_img">
              <img src={images[0].file.url} />
            </div>
            <h4 className="mada-press-note--wrapper_title">{title}</h4>
          </a>
        }
        {
          !link &&
          <a href="javascript:" onClick={()=>handleModalOpen(!isModalOpen)}>
            <div className="mada-press-note--wrapper_img">
              <img src={images[0].file.url} />
            </div>
            <h4 className="mada-press-note--wrapper_title">{title}</h4>
          </a>
        }

      </div>
    </article>
  )
}

export default MadaPressNote
