import React from "react";
import PropTypes from "prop-types"

import 'src/components/Common/MadaButton/_styles.scss';

const MadaButton = ({ link, text, linkTarget }) => {
  return (
    <a href={`${link ? link : '#'}`} target={`_${linkTarget}`} rel="noopener noreferrer" className="hero-button">{text}</a>
  )
}

MadaButton.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string
}

export default MadaButton
